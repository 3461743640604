.slider{
    position: relative;
    width: 100%;

    overflow-x: hidden;
}
.slider .slide-track{
    height: 100%;
    transform: translate3d(0, 0, 0);
    display: flex;
    animation: scroll 50s linear infinite;
}
.slider .slide-track:hover{
    animation-play-state: paused;
}
.slider .slide-track .image_courosel img{
  max-width: 300px;
  max-height: 350px;
  border-radius: 15px;
}

@keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-3000px);
    }
  }