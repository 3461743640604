.aboutUsHero{
    overflow: hidden;
}
.boxParagraph{
    color: var(--Black, #222);
    text-align: center;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.joinAltoTextComponent .layOutDiv{
    padding: 40px 80px;
    display: flex;
    flex-direction: row;
}
.joinAltoTextComponent .layOutDiv .description{
    width: 65%;
    margin-left: auto;
}
.joinAltoTextComponent .layOutDiv .title h2{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.joinAltoTextComponent .layOutDiv .description p{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
}
.joinAltoTextComponent .layOutDiv:nth-child(even){
    background: var(--Background-Light-Blue, rgba(0, 82, 156, 0.04));
}
.joinAltoTextComponent .layOutDiv .description .button-container{
    text-align: start;
    margin-top: 40px;
}
.bacomeAMember{
    padding-top: 5px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-clip: content-box !important;
}
.altoComment li{
    color: var(--Black, #222);
    text-align: center;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ds-card-box{ 
    width: 100%; 
    display: flex; 
    flex-direction: row;
    /* align-items: flex-start; */
    gap: 17px; 
    row-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}
.ds-card-box .ds-card{
    width: 415px; 
    height: auto;
    border-radius: 16px;
    border: 1px solid #00529C;
    background: #FFF;
}
.ds-card-box .ds-card .user-image{
    width: 100% !important; 
    border-radius:  inherit;
}
.ds-card-box .ds-card .user-image img{
    min-width: 100%;
    max-width: 100% !important;
    min-height: 280px;
    max-height: 280px;
    border-radius: 16px 16px 0px 0px;
}
.ds-card-box .ds-card .user-info{
    margin: 20px;
    text-align: start;
}
.ds-card-box .ds-card .user-info .user-name{
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.ds-card-box .ds-card .user-info .user-date{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.ds-card-box .ds-card .user-info .user-description{
    overflow: hidden;
    color: var(--Black, #222);
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}
.ds-card-box .ds-card .user-info .read-more{
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    display: flex;
    gap: 10px;
    cursor: pointer;
}
.ds-card-box .ds-card .user-info .connect-to-linkedin{
    width: 100% !important;
}
@media (max-width: 1500px) {
    .aboutUsPage .aboutUsHero{
        width: 100% !important;
    }
    .aboutUsPage .joinAltoDiv2{
        width: 95% !important;
        height: auto !important;
        padding: 50px 10px !important;
    }
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers{
        width: 100% !important;
        height: auto !important;
        margin: 0px !important;
    }
    .aboutUsPage .bacomeAMember{
        width: 100% !important;
        height: auto !important;
    }
    .aboutUsPage .bacomeAMember .layOutDiv{
        margin: 0px !important;
        flex-direction: column !important;
        padding: 65px 15px !important;
        height: auto !important;
    }
    .aboutUsPage .bacomeAMember .layOutDiv .blueButton{
        margin: 0px !important;
    }
    .aboutUsPage .altoBoard{
        width: 100% !important;
        height: auto !important; 
    }
    .aboutUsPage .altoMemories{
        width: 95% !important;
        padding: 0px !important;
        height: auto !important;
    }
    .aboutUsPage .altoMemories .altoMemoriesDescriptionText{
        width: 100% !important;
    }
    .aboutUsPage .altoFrame{
        width: 95% !important;
        height:  auto !important;
    }
    .aboutUsPage .altoComment{
        width: 95% !important;
        margin: 0px !important;
        padding: 0px !important;
        text-align: center !important;
    }
    .aboutUsPage .altoComment .altoCommentSubDiv{
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;
    }
    .aboutUsPage .altoComment .altoCommentSubDiv .pageHeaderInlineText{
        margin-top: 50px;
    }
    .aboutUsPage .bacomeAMember .layOutDiv .blueButton{
        margin-left: auto !important;
        margin-right: auto !important;
    }

}
@media (max-width: 1450px){
    .ds-card-box .ds-card{
        width: 412px;
    }
}
@media (max-width: 1250px){
    .ds-card-box .ds-card{
        width: 415px;
    }
}
@media (max-width: 1300px){
    .aboutUsPage .altoBoard .peopleCardList{
        width: 100% !important;
        height: auto !important;
    }
}

@media (max-width: 1000px){
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers .layOutDiv{
        flex-direction: column !important;
        text-align: start !important;
        margin: 0px !important;
        width: 100% !important;
        padding: 40px !important;
    }
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers .layOutDiv .description{
        margin: 0px !important;
        width: 100% !important;
    }
    .aboutUsPage .altoBoard{
        margin: 0px !important;
        padding: 30px 20px !important;
    }
    .aboutUsPage .altoMemories{
        margin-top: 50px !important;
    }
    .aboutUsPage .altoMemories .headerStyle{
        font-size: 35px !important;
    }
}
@media (max-width: 700px) {
    .ds-card-box .ds-card .user-image img{
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        min-height: auto !important;
        border-radius: 16px 16px 0px 0px;
    }
}
@media (max-width: 650px){
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers .layOutDiv{
        padding: 40px 20px !important;
    }
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers .layOutDiv:nth-child(4) .button-container{
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        padding: 0px !important;
    }
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers .layOutDiv:nth-child(4) .button-container button{
        margin: 0px !important;
        width: 300px;
    }
    .aboutUsPage .bacomeAMember .layOutDiv .descriptionBecomeAMemeber{
        width: 100% !important;
    }

}
@media (max-width: 450px){
    .aboutUsPage .altoBoard .ds-card-box .ds-card{
        width: 100% !important;
    }
    .aboutUsPage .altoBoard .ds-card-box .ds-card .connect-to-linkedin{
        width: 100% !important;
    }
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers .button-container .default-button, .outlined-button{
        width: 100% !important;
    }
}
@media (max-width: 350px){
    .aboutUsPage .joinAltoTextComponentMissionOrignsMembers .layOutDiv:nth-child(4) .button-container button{
        width: 100% !important;
    }
}