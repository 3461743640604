.event-detail-main{
    width: 100%;
    padding: 55px 80px;
}
.event-detail-main .event-detail-top-content{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 50px;
}
.event-detail-main .event-detail-top-content .left-content{
    width: 50%;
    text-align: start;
}
.event-detail-main .event-detail-top-content .right-content{
    width: 50%;
    text-align: start;
    margin-top: 5px;
}

.event-detail-main .event-detail-top-content .left-content .event-title p{
    color:#222;
    font-size: 48px;
    font-weight: 700;
}

.event-detail-main .event-detail-top-content .left-content .event-detail .event-detail-item{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
}
.event-detail-main .event-detail-top-content .left-content .event-detail .event-detail-key{
    color: #222;
    font-size: 18px;
    font-weight: 700;
    width: 50%;

}
.event-detail-main .event-detail-top-content .left-content .event-detail .event-detail-value{
    color:#00529C;
    font-size: 18px;
    font-weight: 400;
    width: 50%;
}

.event-detail-main .event-detail-top-content .right-content .description p{
    color: #000;
    font-size: 18px;
    font-weight: 400;
    white-space: pre-line;
}
.event-detail-main .event-image img{
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin-top: 40px;
}
.event-detail-main .event-schedules-main{
    width: 100%;
    margin-top: 100px;
}
.event-detail-main .event-schedules-main .event-schedules-title{
    color: var(--Black, #222);
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.event-detail-main .event-schedules-main .event-schedules{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule{
    width: 500px;
    padding: 40px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    text-align: start;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-day-date{
    border-bottom: 1px solid var(--Black-Divider, rgba(34, 34, 34, 0.16));
    padding-bottom: 10px;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-day-date .event-schedule-day{
    color: var(--Black, #222);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-day-date .event-schedule-date{
    color: var(--Black, #222);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 5px;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-activities{
    margin-top: 25px;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-activities .event-schedule-activitiy{
    display: flex;
    flex-direction: row;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-activities .event-schedule-activitiy:not(:last-child){
    border-bottom: 1px solid var(--Black-Divider, rgba(34, 34, 34, 0.16));
    padding-bottom: 15px; 
    margin-bottom: 20px;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-activities .event-schedule-activitiy p{
    color: var(--Black, #222);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-activities .event-schedule-activitiy p:nth-child(1){
    width: 25%;
}
.event-detail-main .event-schedules-main .event-schedules .event-schedule .event-schedule-activities .event-schedule-activitiy p:nth-child(2){
    width: 70%;
    margin-left: auto;
}
.event-detail-main .event-speakers{
    margin-top: 100px;   
}
.event-detail-main .event-speakers .event-speakers-main-title{
    color: #222;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 100px;
}
.event-detail-main .event-speakers .speakers .speaker{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 50px;
    margin: -55px -80px;
    padding: 55px 80px;
}
.event-detail-main .event-speakers .speakers .speaker:not(:last-child){
    margin-bottom: 150px;
}
.event-detail-main .event-speakers .speakers .speaker:nth-child(2n){
    flex-direction: row-reverse;
    gap: 50px;
    background-color: #f4f8fb;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-left-container{
    width: 25%;
    text-align: start;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-right-container{
    width: 100%;
    text-align: start;
}

.event-detail-main .event-speakers .speakers .speaker .speaker-left-container .speaker-image img{
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 15px;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-left-container .speaker-name{
    color: #222;
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-left-container .speaker-linkedin{
    display: block;
    color: #00529C;
    font-size: 18px;
    font-weight: 700;
    margin-top: 12px;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-right-container .speaker-content-title{
    color: #222;
    font-size: 28px;
    font-weight: 700;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-right-container .speaker-content-description{
    color: #222;
    font-size: 18px;
    font-weight: 400;
    margin-top: 25px;
    white-space: pre-line;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-right-container .video-container{
    margin-top: 50px;
    border-radius: 15px !important;
}
.event-detail-main .event-speakers .speakers .speaker .speaker-right-container .video-container video{
    border-radius: inherit;
    outline: none;
    box-shadow: none;
}
.event-detail-main .event-sponsor-info{
    width: 100%;
    margin-top: 100px;
}
.event-detail-main .event-sponsor-info .event-sponsor-info-text{
    color: var(--Black, #222);
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.event-detail-main .event-sponsor-info .event-sponsor-info-logo{
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    max-width: 100%;
}


@media (max-width: 900px) {
    .event-detail-main .event-detail-top-content{
        flex-direction: column;
        width: 100%;
        gap: 50px;
    }
    .event-detail-main .event-detail-top-content .left-content,
    .event-detail-main .event-detail-top-content .right-content{
        width: 100%;
    }
    .event-detail-main{
        padding: 55px 20px;
    }
    .event-detail-main .event-speakers .speakers .speaker:nth-child(2n){
        flex-direction: column;
    }
}
@media (max-width: 700px) {
    .event-detail-main .event-speakers .speakers .speaker{
        flex-direction: column;
        width: 100%;
        margin: 0px;
        padding: 55px 10px;
        background-color: #FFF !important;
    }
    .event-detail-main .event-speakers .speakers .speaker .speaker-right-container,
    .event-detail-main .event-speakers .speakers .speaker .speaker-left-container{
        width: 100%;
    }
    .event-detail-main .event-speakers .speakers .speaker .speaker-left-container img{
        margin-top: -200px;
    }
    .event-detail-main .event-detail-top-content .left-content .btn-event-register{
        width: 100% !important;
    }
}
@media (max-width: 500px) {
    .event-detail-main .event-schedules-main .default-button{
        width: 100% !important;
    }
}