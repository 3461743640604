.header .header__content nav .mainListRight{
    margin: 0px;
}
.header .header__content nav .mainList .mainItem{
    position: relative !important;
}
.header .header__content nav .mainList .mainItem .subList{
    min-width: 350px !important;
    list-style-type: none !important;
    position: absolute !important;
    top: 100px !important;
    background-color: #00529C;
    color: white;
    text-align: left;
    display: none;
    z-index: 10 !important;
}
.header .header__content nav .mainList .mainItem .subList .subItem{
    width: 100% !important;
    padding: 15px 30px;
    color: var(--White, #FFF);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.header .header__content nav .mainList .mainItem .subList .subItem .subItemLink{
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    margin: 0px !important;
    padding: 0px !important;
}
.header .header__content nav .mainList .mainItem .subList .subItem:hover{
    background-color: #076ac0;
}
.header .header__content nav .mainList .mainItem .subList .subItem:not(:last-child){
    border-bottom: 1px solid #FFFFFF;
}
.header .header__content nav .mainList .mainItem:hover{
    font-size: 18px !important;
    font-weight: 700 !important;
}
.header .header__content nav .mainList .mainItem .rightIcon{
    display: none;
    transition: all 0.5s ease; 
}
.header .header__content .search-bar {
    width: 87%;
    position: absolute;
    z-index: 99999999 !important;
    margin-left: 10%;
}
.header .header__content .search-bar input{
    width: 100% !important;
    border-radius: 16px;
    border: 1px solid var(--Black-Divider, rgba(34, 34, 34, 0.16));
    background: var(--White, #FFF);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    outline: none !important;
    padding: 25px;
}

.rotateIconDown{
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

@media (max-width: 1600px) {
    .header .header__content .search-bar {
        margin-left: 12%;
        width: 85%;
    }
}

@media (max-width: 1200px) {
    .header .header__content .search-bar {
        margin-left: 15%;
        width: 80%;
    }
}


@media (min-width: 1056px) {
    .header .header__content nav .mainList .mainItem:hover > .subList{
        display: block !important;
    }
}

@media (max-width: 1055px){
    .header .header__content nav .mainList .mainItem{
        display: flex !important;
        flex-direction: column !important;
        overflow: hidden;
    }
    .header .header__content nav .mainList .mainItem .subList{
        position: static !important;  
    }
    .header .header__content nav{
        padding-top: 120px;
    }
    .header .header__content .search-bar {
        position: fixed;
        top: 85px !important;
        right: 10px;
        width: 95% !important;
        margin: 0px !important;
    }
    .header .header__content .search-bar input{
        padding: 20px 30px;
    }
}