.blog-detail-main .return-main-blog-page-arrow{
    margin-left: 70px;
    margin-top: 20px;
    width: 25px;
    cursor: pointer;
}
.blog-detail-main .main-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 30px 70px 40px 70px;
}
.blog-detail-main .main-content .left-content{
    width: 65%;
    text-align: left;
}
.blog-detail-main .main-content .right-content{
    width: 35%;
    text-align: left;
}
.blog-detail-main .main-content .left-content .blog-image img{
    width: 100%;
    max-width: 100%;
    height: auto;
}
.blog-detail-main .main-content .left-content .blog-title p{
    color:#222;
    font-size: 48px;
    font-weight: 700;
    margin: 40px 0px;
}
.blog-detail-main .main-content .left-content .blog-paragraphs .blog-paragraph:not(:last-child){
    margin-bottom: 40px;
}
.blog-detail-main .main-content .left-content .blog-paragraphs .blog-paragraph .blog-paragraph-sub-heading{
    color: #222;
    font-size: 28px;
    font-weight: 700;
}
.blog-detail-main .main-content .left-content .blog-paragraphs .blog-paragraph .blog-paragraph-description{
    color: #222;
    font-size: 18px;
    font-weight: 400;
    margin: 40px 0px;
}
.blog-detail-main .main-content .left-content .blog-paragraphs .blog-paragraph .blog-paragraph-image{
    max-width: 100%;
    height: auto;
}
.blog-detail-main .main-content .right-content .search-container{
    position: relative;
}
.blog-detail-main .main-content .right-content .search-container img{
    position: absolute;
    top: 20px;
    left: 15px;
}
.blog-detail-main .main-content .right-content .search-container input{
    padding: 20px;
    width: 100%;
    color: #BDBDBD;
    font-size: 18px;
    font-weight: 400;
    border-radius: 16px;
    border: 1px solid var(--Black-Divider, rgba(34, 34, 34, 0.16));
    background: var(--White, #FFF);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    outline: none;
    padding-left: 55px;
}
.blog-detail-main .main-content .right-content .blog-left-content-common-title{
    color: #222;
    font-size: 28px;
    font-weight: 700;
    margin-top: 40px;
}
.blog-detail-main .main-content .right-content .categories-container .categories{
    width: 100%;
}
.blog-detail-main .main-content .right-content .categories-container .categories .category{
    color:#00529C;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 7px;
    margin-top: 20px;
    cursor: pointer;
}
.blog-detail-main .main-content .right-content .categories-container .categories .category p{
    display: inline-block;
}
.blog-detail-main .main-content .right-content .categories-container .categories .category img{
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
.blog-detail-main .main-content .right-content .recent-blogs{
    margin-top: 15px;
}
.blog-detail-main .main-content .right-content .recent-blogs .blog-title{
    color: #222;
    font-size: 18px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 10px;
    cursor: pointer;
}
.blog-detail-main .main-content .right-content .recent-blogs .date-and-comment{
    font-size: 15px;
    font-weight: 400;
}
.blog-detail-main .main-content .right-content .recent-blogs .date-and-comment .blog-date{
    color: #222;
}
.blog-detail-main .main-content .right-content .recent-blogs .date-and-comment .blog-comment{
    color: #00529C;
}
@media (max-width: 1200px) {
    .blog-detail-main .main-content{
        padding: 30px 15px 40px 15px;
    }
    .blog-detail-main .return-main-blog-page-arrow{
        margin-left: 15px;
    }
}
@media (max-width: 750px) {
    .blog-detail-main .main-content{
        flex-direction: column;
        gap: 50px;
    }
    .blog-detail-main .main-content .left-content,
    .blog-detail-main .main-content .right-content{
        width: 100%;
        margin: 0px;
    }
}