.membersMain{
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.membersMain .banner{
    width: 100%;
    height: 640px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: content-box;
}
.membersMain .welcome-to-our-members{
    width: 100%;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 60px;
}
.membersMain .welcome-to-our-members div:nth-child(1) {
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}
.membersMain .welcome-to-our-members div:nth-child(2) {
    width: 720px;
    margin-left: auto;
    text-align: start;
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.membersMain .welcome-to-our-members div:nth-child(2) span{
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;
}
.membersMain .welcome-to-our-members div:nth-child(2) .member-list-link{
    color: var(--Blue, #00529C);
    font-weight: 700;
    border-bottom: 1px solid var(--Blue, #00529C);
    width: fit-content;
    margin-top: 50px;
    cursor: pointer;
}

.membersMain .member-list .members-tab-buttons{
    display: flex;
    gap: 100px;
    margin-top: 50px;
    align-items: self-end;
    justify-content: center;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
}
.membersMain .member-list .members-tab-buttons .tab-button{
    color: var(--Black, #222);
    text-align: center;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    width: 30%;
}
.membersMain .member-list .members-tab-buttons .active-tab-button{
    color:#00529C;
    border-bottom: 2px solid #00529C;
    font-weight: 700;
}

.membersMain .member-list .members-tabs{
    width: 95%;
    margin-top: 50px;
    padding-bottom: 50px;
    margin-right: auto;
    margin-left: auto;
}
.membersMain .member-list .members-tabs .table{
    border-radius: 16px;
    border: 1px solid rgba(34, 34, 34, 0.12);
    background: var(--White, #FFF);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
}
.membersMain .member-list .members-tabs .table-title{
    background: var(--Background-Light-Blue, rgba(0, 82, 156, 0.04));
    padding: 16px 40px;
    text-align: start;
}
.membersMain .member-list .members-tabs .table-title p{
    color: var(--Black, #222);
    font-family: DM Sans; 
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.membersMain .member-list .members-tabs .table-content{
    padding: 16px 40px;
}
.membersMain .member-list .members-tabs .table-content .columns{
    display: flex;
}
.membersMain .member-list .members-tabs .table-content .columns p{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.membersMain .member-list .members-tabs .table-content .rows{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}
.membersMain .member-list .members-tabs .table-content .rows .row{
    padding: 17px 0px;
}
.membersMain .member-list .members-tabs .table-content .rows .row:not(:last-child){
    border-bottom: 1px solid #00529C;
}
.membersMain .member-list .members-tabs .table-content .rows .row p{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}
.membersMain .banner .newsContainer .newsHeader{
    text-align: start;
}
@media (max-width: 1500px) {
    .membersMain .banner{
        width: 100% !important;
    }
    .membersMain .member-list {
        margin: 10px !important;
    }
    .membersMain .welcome-to-our-members{
        width: 100% !important;
    }
    .membersMain .member-list .members-tabs{
        width: 100%;
    }

}
@media (max-width: 1250px) {
    .membersMain .welcome-to-our-members{
        flex-direction: column;
        align-items: center;
        gap: 30px !important;
        padding: 20px;
    }
    .membersMain .welcome-to-our-members > div{
        width: 100% !important;
    }
    .membersMain .welcome-to-our-members > div:nth-child(2){
        margin: 0px !important;
    }
}
@media (max-width: 1000px) {
    .membersMain .banner .newsContainer{
        width: 100% !important;
        padding: 20px !important;
        height: auto !important;
        text-align: left;
    }
    .membersMain .banner .bannerDescriptionInner{
        width: 100% !important;
    }
    .membersMain .banner .newsContainer .newsHeader{
        font-size: 50px !important;
    }
    .membersMain .banner .newsContainer .joinAltoHeader2{
        font-size: 20px !important;
    }
    .membersMain .member-list .members-tabs .table-content{
        padding: 20px 10px;
    }


}
@media (max-width: 650px){
    .membersMain .member-list .members-tab-buttons{
        width: 100%;
        gap: 30px !important;
    }
    .membersMain .member-list .members-tab-buttons p{
        font-size: 20px !important;
    }
}
@media (max-width: 480px){
    .membersMain .member-list .members-tabs .table-content .columns p{
        width: 30% !important;
    }
    .membersMain .member-list .members-tabs .table-content .rows .row p{
        width: 30% !important;
    }
}
@media (max-width: 350px){
    .membersMain .member-list .members-tab-buttons p{
        font-size: 18px !important;
    }
}