.event-register-main{
    width: 100%;
}
.event-register-main .event-main-image{
    background-color: #00529C;
    position: relative;
}
.event-register-main .event-main-image img{
    width: 100%;
    opacity: 0.5;
}
.event-register-main .event-main-image h1{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--White, #FFF);
    text-align: center;
    font-family: DM Sans;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 96px; /* 120% */
    width: 100%;
}
.event-register-main .register-form-container{
    top: -250px;
    position: relative;
}
.event-register-main .register-form-container .form-title{
    color: #222;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.event-register-main .register-form-container .form-description{
    color:#222;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 30px 0px !important;
}
.event-register-main .register-form-container .form{
    width: 650px;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 40px;
    border-radius: 16px;
    margin-top: 60px;
    border-radius: 16px;
    border-top: 16px solid var(--Dark-Light-Blue-Gradient, #00529C);
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
}
.event-register-main .register-form-container .form .formGroup{
    margin-bottom: 20px;
    text-align: start;
    width: 100%;
}
.event-register-main .register-form-container .form .formGroup input{
    width: 100%;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #BDBDBD;
    padding-bottom: 5px;
}
.event-register-main .register-form-container .form .formGroup .inputTitle{
    color: var(--black, #222);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.event-register-main .register-form-container .form .firstNameAndLastName{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.event-register-main .register-form-container .form .formGroup:not(:last-child){
    margin-bottom: 40px;
}
.event-register-main .register-form-container .form .formGroup .group{
    display: flex;
    margin-top: 20px;
    gap: 30px;
}
.event-register-main .register-form-container .form .formGroup .group .radio-group{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center !important;
}
.event-register-main .register-form-container .form .formGroup .group .radio-group label{
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
    margin-top: 5px;
}

.event-register-main .register-form-container .form .btnSend{
    width: 100% !important;
    padding: 12px 40px;
    border-radius: 8px;
    background: var(--blue, #00529C);
    color: var(--white, #FFF);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 650px) {
    .event-register-main .register-form-container{
        padding: 0px 10px 50px 10px;
    }
    .event-register-main .register-form-container .form{
        width: 95% !important;
        padding: 30px !important;
    }
    .event-register-main .register-form-container .form .firstNameAndLastName{
        flex-direction: column !important;
        gap: 0px;
        margin-bottom: 20px;
    }

}