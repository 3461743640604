.cardComponent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 40px;
    gap: 40px;
    width: 500px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    min-height: 550px;
    height: auto;
}
.cardComponent .cardComponentContentText{
    padding: 0px 20px;
    text-align: left;
}
.cardComponent .cardComponentImage img{
    width: 100%;
    max-height: 300px;
    min-height: 300px;
}
.cardComponent .cardComponentContentText .cardComponentFileDate{
    color: black, #222;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.cardComponent .cardComponentContentText .cardComponentFileDate span{
    color: var(--blue, #00529C);
}
.cardComponent .cardComponentContentText .cardComponentFileTitle{
    color: #222;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 15px 0px;
}
.cardComponent .cardComponentContentText .cardComponentFileText{
    color: var(--black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width:1000px){
    .cardComponentImage{
        width: 400px !important;
    }
}