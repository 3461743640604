.news-and-project-detail-main .main-container{
    padding: 50px 0px 50px 80px;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}
.news-and-project-detail-main .main-container .left-section{
    width: 52%;
    text-align: start;
}
.news-and-project-detail-main .main-container .right-section{
    width: 45%;
    text-align: end;
    margin-left: auto;
}

.news-and-project-detail-main .main-container .left-section .title p{
    color: #222;
    font-size: 48px;
    font-weight: 700;
}
.news-and-project-detail-main .main-container .left-section .sub-title p{
    color:#00529C;
    font-size: 48px;
    font-weight: 700;
}
.news-and-project-detail-main .main-container .left-section .description{
    color: #000;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 40px;
}
.news-and-project-detail-main .main-container .left-section .please-complete-button{
    margin-top: 40px;
}
.news-and-project-detail-main .main-container .left-section .please-complete-button button{
    width: fit-content !important;
}
.news-and-project-detail-main .main-container .left-section .main-link{
    margin-top: 40px;
}
.news-and-project-detail-main .main-container .left-section .main-link a{
    color: #00529C;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 7px;
}
.news-and-project-detail-main .main-container .left-section .links {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 40px;
}
.news-and-project-detail-main .main-container .left-section .links a{
    color: #00529C;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 7px;
    cursor: pointer;
}
.news-and-project-detail-main .main-container .right-section .image-content{
    display: flex;
}
.news-and-project-detail-main .main-container .right-section .image-content img{
    margin-left: auto;
}
.news-and-project-detail-main .sub-headings{
    width: 100%;
    background: rgba(0, 82, 156, 0.04);
    padding: 55px 80px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    text-align: start;
}
.news-and-project-detail-main .sub-headings .sub-title{
    width: 40%;
    color: #222;
    font-size: 28px;
    font-weight: 700;
}
.news-and-project-detail-main .sub-headings .sub-description{
    width: 50%;
    margin-left: auto;
    color: #000;
    font-size: 18px;
    font-weight: 400;
}
.news-and-project-detail-main .sub-headings .sub-description .default-button{
    width: fit-content !important;
    margin-top: 50px;
}
.news-and-project-detail-main .sub-headings .sub-description .sub-heading-link{
    margin-top: 30px;
}
.news-and-project-detail-main .sub-headings .sub-description .sub-heading-link a{
    color:#00529C;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 7px;
    cursor: pointer;
}

.news-and-project-detail-main .contact-container{
    width: 100%;
    height: auto;
    background: linear-gradient(92deg, rgba(0, 82, 156, 0.80) 0.97%, rgba(255, 255, 255, 0.90) 100%), url(../images/homepage/footer-hero.jpg), lightgray 50% / cover no-repeat !important;
    background-size: cover !important; 
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-clip: content-box !important;
}
.news-and-project-detail-main .contact-container .inner{
    padding: 40px 0px !important;
}
.news-and-project-detail-main .contact-container .title{
    text-align: center !important;
}
.news-and-project-detail-main .contact-container .title p{
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    text-align: center !important;
}
.news-and-project-detail-main .contact-container .form{
    width: 500px;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 40px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.94); 
    margin-top: 60px;
    margin-bottom: 60px;
}
.news-and-project-detail-main .contact-container .form .formGroup{
    margin-bottom: 20px;
    text-align: start;
    width: 100%;
}
.news-and-project-detail-main .contact-container .form .formGroup input{
    width: 100%;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #BDBDBD;
    padding-bottom: 5px;
}
.news-and-project-detail-main .contact-container .form .formGroup .inputTitle{
    color: var(--black, #222);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.news-and-project-detail-main .contact-container .form .firstNameAndLastName{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.news-and-project-detail-main .contact-container .form .formGroup:not(:last-child){
    margin-bottom: 40px;
}

.news-and-project-detail-main .contact-container .form .btnSend{
    width: 100%;
    padding: 12px 40px;
    border-radius: 8px;
    background: var(--blue, #00529C);
    color: var(--white, #FFF);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.news-and-project-detail-main .contact-container .form .permission{
    display: flex;
    flex-direction: row;
} 
.news-and-project-detail-main .contact-container .form .permission input{
    width: 30px;
}
.news-and-project-detail-main .contact-container .form .bottom-description{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    margin-bottom: 30px;
}
.news-and-project-detail-main .navigation-bottom{
    margin: 0px 80px;
}
@media (max-width: 1500px) {
    .news-and-project-detail-main .contact-container{
        width: 100% !important;
    }
}

@media (max-width: 1000px){
    .news-and-project-detail-main .main-container {
        padding: 0px !important;
        margin-top: 40px;
        flex-direction: column-reverse !important;
    }
    .news-and-project-detail-main .main-container .left-section, .news-and-project-detail-main .main-container .right-section{
        width: 100%;
    }
    .news-and-project-detail-main .main-container .left-section{
        padding: 0px 20px;
        margin-top: 40px;
    }   
}
@media (max-width: 800px){
    .news-and-project-detail-main .sub-headings{
        padding: 55px 20px;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .news-and-project-detail-main .sub-headings .sub-title{
        width: 100%;
    }
    .news-and-project-detail-main .sub-headings .sub-description{
        width: 100%;
        margin: 0px;
    }
    .news-and-project-detail-main .navigation-bottom{
        margin: 0px;
    }
}
@media (max-width: 650px) {
    .news-and-project-detail-main .contact-container{
        padding: 0px 10px 50px 10px;
    }
    .news-and-project-detail-main .contact-container .form{
        width: 95% !important;
        padding: 30px !important;
    }
    .news-and-project-detail-main .contact-container .form .firstNameAndLastName{
        flex-direction: column !important;
        gap: 0px;
        margin-bottom: 20px;
    }
}
@media (max-width: 485px){
    .news-and-project-detail-main .main-container .left-section .title p{
        font-size: 32px;
    }
    .news-and-project-detail-main .main-container .left-section .sub-title p{
        font-size: 32px;
    }
}