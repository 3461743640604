.blog-main .blog-main-banner{
    overflow: hidden;
}
.blog-main .main-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 40px 70px;
}
.blog-main .main-content .right-content{
    width: 65%;
}
.blog-main .main-content .left-content{
    width: 35%;
    text-align: left;
}
.blog-main .main-content .right-content .items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}
.blog-main .main-content .right-content .items .item{
    width: 48%;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
}
.blog-main .main-content .right-content .items .item .image-container img{
    width: 100%;
    min-height: 350px;
    max-height: 350px;
    height: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    object-fit: cover;
}
.blog-main .main-content .right-content .items .item .item-content{
    padding: 40px 20px;
}
.blog-main .main-content .right-content .items .item .item-content .top{
    display: flex;
    flex-direction: row;
}
.blog-main .main-content .right-content .items .item .item-content .top p:nth-child(1){
    color: #222;
    font-size: 15px;
    font-weight: 400;
}
.blog-main .main-content .right-content .items .item .item-content .top p:nth-child(2){
    color:#00529C;
    font-size: 15px;
    font-weight: 400;
    margin-left: auto;
}
.blog-main .main-content .right-content .items .item .item-content .title p{
    text-align: start;
    margin-top: 15px;
    color: #222;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}
.blog-main .main-content .right-content .items .item .item-content .description p{
    color: #222;
    text-overflow: ellipsis;
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 400;
    text-align: start;
    margin-top: 15px;
}
.blog-main .main-content .right-content .items .item .item-content .read-more{
    text-align: start;
    margin-top: 15px;
    cursor: pointer;
}
.blog-main .main-content .right-content .items .item .item-content .read-more a{
    color: #00529C;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #00529C;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-align: start;
}
.blog-main .main-content .left-content .search-container{
    position: relative;
}
.blog-main .main-content .left-content .search-container img{
    position: absolute;
    top: 20px;
    left: 15px;
}
.blog-main .main-content .left-content .search-container input{
    padding: 20px;
    width: 100%;
    color: #BDBDBD;
    font-size: 18px;
    font-weight: 400;
    border-radius: 16px;
    border: 1px solid var(--Black-Divider, rgba(34, 34, 34, 0.16));
    background: var(--White, #FFF);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    outline: none;
    padding-left: 55px;
}
.blog-main .main-content .left-content .blog-left-content-common-title{
    color: #222;
    font-size: 28px;
    font-weight: 700;
    margin-top: 40px;
}
.blog-main .main-content .left-content .social .social-icons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 25px;
}
.blog-main .main-content .left-content .categories-container .categories{
    width: 100%;
}
.blog-main .main-content .left-content .categories-container .categories .category{
    color:#00529C;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 7px;
    margin-top: 20px;
    cursor: pointer;
}
.blog-main .main-content .left-content .categories-container .categories .category p{
    display: inline-block;
}
.blog-main .main-content .left-content .categories-container .categories .category img{
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
.blog-main .main-content .left-content .recent-blogs{
    margin-top: 15px;
}
.blog-main .main-content .left-content .recent-blogs .blog-title{
    color: #222;
    font-size: 18px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 10px;
    cursor: pointer;
}
.blog-main .main-content .left-content .recent-blogs .date-and-comment{
    font-size: 15px;
    font-weight: 400;
}
.blog-main .main-content .left-content .recent-blogs .date-and-comment .blog-date{
    color: #222;
}
.blog-main .main-content .left-content .recent-blogs .date-and-comment .blog-comment{
    color: #00529C;
}
@media (max-width: 1200px) {
    .blog-main .main-content{
        padding: 70px 20px;
    }
    .blog-main .main-content .right-content .items{
        flex-direction: column;
        gap: 50px;
        margin: 0px;
    }
    .blog-main .main-content .right-content .items .item{
        width: 100%;
        margin: 0px;
    }
}
@media (max-width: 750px) {
    .blog-main .main-content{
        flex-direction: column;
        gap: 50px;
    }
    .blog-main .main-content .left-content,
    .blog-main .main-content .right-content{
        width: 100%;
        margin: 0px;
    }

        .blog-main .main-content .right-content .items .item .image-container img {
            min-height: 250px;
        }
}