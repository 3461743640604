.homePageMembers{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    margin: 0px auto;
    width: auto;
    height: 874px;
    background: #FFFFFF;
}
.homePageaboutUs .homepageaboutUsImage{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-clip: content-box !important;
}
.right-rotate-images{
    margin-left: auto !important;
}
.homePageContainerInner .ellipse{
    position: absolute;
    top: 350px;
}
.homePage_container .homePageContainerInner .homePageHeaderContainer .pageHeaderText .leaders-text{
    display: inline-block
}

@media (max-width: 1500px) {

    .homePage_container .homePageContainerInner{
        height: auto;
        padding-bottom: 150px;
    }
    .homePageContainerInner .ellipse{
        position: absolute;
        top: 600px;
        left: -900px;
    }
    .homePageaboutUs .homepageaboutUsImage{
        width: 100%;
    }
    .homePageBecomeMember{
        padding: 0px !important;
        margin: 0 auto !important;
        height: auto !important;
        width: 95% !important;
        margin-bottom: 50px !important;
    }
    .homePageSection2{
        width: 100% !important;
        padding: 40px 15px;
    }
    .homePageSection2 .cardItemhomePageSection2{
        margin-top: -15px;
        width: 100% !important;
        overflow-x: scroll !important;
        padding: 10px 0px;
    }
    .homePageSection2 .cardItemhomePageSection2::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
    .homePageSection2 .cardItemhomePageSection2 {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    .right-rotate-images{
        height: auto;
        margin-left: auto !important;
        margin-top: 30px;
    }
}
@media (max-width: 1300px) {
    .homePageaboutUs {
        height: auto;
    }
    .homePageaboutUs .home-about-us-container{
        display: flex !important;
        flex-direction: column !important;
        gap: 30px !important;
        height: auto !important;
        align-items: normal !important;
    }
    .homePageaboutUs .home-about-us-container .boxContainer{
        width: 100%;
        height: auto;
    }
}
@media (max-width: 1000px) {
    .homePageaboutUs{
        width: 90%;
        margin: 0px;
        padding: 0px;
        margin: 40px auto;
    }
    .homepageButtons{
        display: flex;
        flex-direction: column;
        align-items: end;
        padding: 0px;
        gap: 19.78px;
        width: auto;
        margin: 0px;
    }
    .homePageContainerInner .ellipse{
        position: absolute;
        top: 750px;
        left: -1000px;
    }
    .homePageContainerInner .pageHeaderText{
        width: 100%;
    }
    .homePageContainerInner .homePageContainerText{
        padding-right: 15px;
        width: 100% !important;
    }
    .homePageContainerInner .pageHeaderText .leaders-meet-text{
        width: 250px !important;
    }
    .homepageaboutUsImage {
        display: flex;
        flex-direction: column;
        gap: 0px;
        height: auto;
    }
    .homepageaboutUsImage .blueButton{
        margin: 0px;
        margin-bottom: 60px;
    }
    .homepageaboutUsImage .blueButtonOutline{
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .homepageaboutUsImage p{
        margin: 60px 30px 30px 30px !important
    }
    .homePageSection2 .cardItemhomePageSection2{
        justify-content: flex-start !important;
        flex-wrap: nowrap !important;
    }
}
@media (max-width: 950px) {
    .homePageMemories{
        width: 100% !important;
        height: auto !important;
        flex-direction: column !important;
        padding: 0px !important;
        margin: 0px !important;
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .homePageMemories .homePageSection1{
        width: 100% !important;
        overflow: hidden;
        flex-direction: column !important;
        height: auto !important;
        padding: 0px !important;
        margin: 0px !important;
        padding-right: 20px !important;
        padding-top: 50px !important;
        align-items: flex-start !important;
        gap: 0px !important;
        padding-bottom: 50px !important;
    }
    .homePageMemories .homePageSection1 .subSection{
        width: 100% !important;
        height: auto !important;
        padding: 0px !important;
        margin: 0px !important;
        align-items: flex-start !important;
        gap: 20px !important;
        padding: 20px 0px 20px 20px !important;
    }
    .homePageMemories .homePageSection1 .subSectionChildN{
        flex-direction: column-reverse !important;
    }
    .homePageMemories .homePageSection1 .subSectionChildN .right-rotate-images{
        width: 100% !important;

    }
    .homePageMemories .homePageSection1 .subSectionChild2N{
        flex-direction: column !important;
    }
    .homePageMemories .homePageSection1 .subSectionChild2N .left-rotate-images{
        width: 100% !important;

    }
    .homePageMemories .homePageSection1 .subSection:last-child{
        margin-bottom: 80px !important;
    }
    .homePageMemories .homePageSection1 .subSection .homePageAltoMemoryCard{
        width: 100% !important;
        height: auto !important;
        margin: 0px !important;
        margin-top: -250px !important;
    }
    .homePageMemories .homePageSection1 .subSection .homePageAltoMemoryCard{
        width: 100% !important;
        height: auto !important;
        margin: 0px !important;
        margin-top: 0px !important;
    }
}
@media (max-width: 800px) {
    .homePageMembers{
        width: 100% !important;
        height: auto !important;
        padding: 0px 20px !important;
        margin: 0px !important;
    }
    .homePageMembers .homePageMembersInner{
        width: 100% !important;
        height: auto !important;
    }
    .homePageMembers .homePageMembersInner img{
        max-width: 100% !important;
        min-height: 250px !important;
        height: auto !important;
    }
    .homePageMembers .homePageMembersInner .homePageAltoText{
        width: 100% !important;
        height: auto !important;
        margin: 0px !important;
        padding: 25px 0px !important;
        position: relative;
        left: 0px;
        top: -75px;
    }
    .homePageMembers .homePageMembersInner .homePageAltoText .pageHeaderInlineText{
        width: 100%;
    }
    .homePageBecomeMember .homePageBecomeMemberDiv{
        width: 100% !important;
        height: auto !important;
        margin: 0px !important;
    }
    .homePageBecomeMember .homePageBecomeMemberDiv .boxParagraph{
        width: 100% !important;
        height: auto !important;
        margin: 0px !important;
        padding: 0px !important;
        text-align: center;
    }
}
@media(max-width: 650px){
    .homePageMemories .homePageSection1 .subSection .imageContainerDiv{
        margin-top: 100px !important;
    }
    .homePageMemories .homePageSection1 .subSection .imageContainerDiv .ellipse2{
        width: 400px !important;
        height: 400px !important;
        left:10%;
        top: -350px;
    }
    .homePageMemories .homePageSection1 .subSection .imageContainerDiv img{
        width: 300px !important;
        height: 400px !important;
    }
    .homePageMemories .homePageSection1 .subSection .homePageAltoMemoryCard{
        padding: 0px !important;
    }
    .homePageMemories .homePageSection1 .subSection:nth-child(2){
        padding-top: 0px !important;
    }
    .homePageMemories .homePageSection1 .subSection:nth-child(2) .homePageAltoMemoryCard{
        margin-top: -150px !important;
    }
    .homePageMemories .homePageSection1 .subSection:nth-child(3) .imageContainerDiv{
        margin-top: -50px !important;
    }

}
@media (max-width: 550px){
    .homePageMemories .homePageSection1 .subSection:nth-child(2) .imageContainerDiv{
        margin-left: -5% !important;
    }
}
@media (max-width: 475px){
    .homePageMemories .homePageSection1 .subSection .imageContainerDiv .ellipse2{
        width: 300px !important;
        height: 300px !important;
        left:50px;
        top: -250px;
    }
    .homePageMemories .homePageSection1 .subSection .imageContainerDiv img{
        width: 200px !important;
        height: 300px !important;
    }
    .homePageMemories .homePageSection1 .subSection:nth-child(2) .homePageAltoMemoryCard{
        margin-top: -200px !important;
    }
}

@media (max-width: 550px){
    .homePageMemories .homePageSection1 .subSection:nth-child(2) .imageContainerDiv{
        margin-left: -10% !important;
    }
}