.eventsHomePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
    position: relative;
    margin: 0px auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: content-box;
    width: 100%;
    height: 640px;
    overflow: hidden;
}
.eventsHomePage .ellipseBlue{
    width: 1305px;
    height: 1000px;
    position: absolute;
    left: -320px;
    bottom: -305px;
    border-radius: 1305px;
    background: var(--50-blue, rgba(0, 82, 156, 0.50));
}
.upcomingEvents{
    padding: 56px 0px;
    position: relative;
    margin: 0px auto;
    width: 100%;
    height: auto !important;
    background: #FFFFFF;
}
.eventsHeader{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;;
    color: #222222;
}
.eventsText{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #222222;
    margin: 40px 0px;
}
.eventsFrame{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 80px;
    gap: 40px;
    width: 100%;
    justify-content: center;
    row-gap: 150px;
    padding-bottom: 80px;
}
.eventsCardComponent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 30%;
    height: 400px;
    border-radius: 16px;
}
.dateEvents{
    margin-top: 20px;
    /* Dm Sans/Subtitle 1 */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
}
.cardHeader{
    margin-top: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    text-align: left;
    align-items: center;
    color: #00529C;
}
.cardText{
    margin-top: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: left;
    /* Black */
    color: #222222;
}
.pastEventsContainer{
    padding: 56px 0px;
    width: 100%;
    position: relative;
    margin: 0px auto;
    background: #FFFFFF;
}
.chips{
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 4px;
    margin-bottom: 30px;
    justify-content: center;
}
.chips .year{
    padding: 4px 20px;
    border: 1px solid #BDBDBD;
    border-radius: 28px;
    cursor: pointer;
}
.chips .active{
    background-color: #00529C;
    color: white;
    border: #00529C;
}
.chips .year .year-text{
    margin: 0px;
}
.eventPageEvent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    justify-content: center;
    row-gap: 150px;
}
.evntsArchive{
    position: relative;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    gap: 20px;
    width: 100%;
    margin-top: 100px;
}
.pastEventCardComponent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    width: 400px;
}
.pastEventCardComponent:not(:last-child){
    margin-bottom: 50px;
}
.textButtonCard{
    margin-top:15px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    /* Blue */
    color: #00529C;
    text-decoration: underline; 
    text-underline-offset: 8px;
    cursor: pointer;
}
.pastEventCardComponentHeader{
    margin-top: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    text-align: left;
    align-items: center;
}
.section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 375px;
    position: relative;
    margin: 0px auto;
    background: linear-gradient(92deg, rgba(0, 82, 156, 0.80) 0.97%, rgba(255, 255, 255, 0.90) 100%), url(../images/events/want_to_make_sure.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: content-box;
}
.sectionText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    width: 100%;
    padding: 0px 50px;
}
.eventDetailSection1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0px;
    gap: 40px;
    position: relative;
    margin: 0px auto;
    width: 1440px;
    background-color: white;
}
.imgDiv{
    width: 1280px;
    height: 640px;  
    border-radius: 16px;
}
.divContainerRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
}
.divContainerColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
}
.eventInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 620px;
    height: 147px;
}
.blueCardText{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    /* Blue */
    text-align: left;
    color: #00529C;
}
.plannedSchedule{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    gap: 40px;
    position: relative;
    margin: 0px auto;
    width: 1440px;
    /* height: 784px; */
    background: #FFFFFF;
}
.scheduleCardsDiv{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    width: 1280px;
    height: 377px;
}
.scheduleCard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 10px;
    width: 400px;
    height: 377px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;}
.eventFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    height: 380px;
}
.event{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 24px;
    width: 320px;
    height: 62px;
    /* Black Divider */
    border-bottom: 1px solid rgba(34, 34, 34, 0.16);
}
.date{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 8px;
    gap: 10px;
    width: 320px;
    height: 48px;
    /* Black Divider */
    border-bottom: 1px solid rgba(34, 34, 34, 0.16);
}
.keynotesSpeaker{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0px 0px;
    gap: 40px;
    position: relative;
    margin: 0px auto;
    width: 1440px;
}
.frame{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    /* width: 1280px; */
    /* height: 1093.5px; */
}
.speakerProfile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    width: 400px;
    height: 496px;
}
.speakerInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    overflow: hidden;
    width: 840px;
}
.video{
    width: 840px;
    height: 472.5px;
    margin-top: 25px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border-radius: 16px;
}
.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 56px 0px;
    gap: 40px;
    width: 1440px;
    height: 159px;
}
.eventList{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 80px;
    flex-wrap: wrap; /* Kartlar yeni satıra geçsin */
    gap: 40px;
    width: 1440px;
    overflow-x: hidden;
}
.eventPosition{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    gap: 40px;
    width: 1440px;
    position: relative;
    margin: 0px auto;
}
.neventFrame{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 80px;
    flex-wrap: wrap; /* Kartlar yeni satıra geçsin */
    gap: 40px;
    width: 1440px;
    overflow-x: hidden;
}
@media (max-width: 1500px) {
    .eventsHomePage{
        width: 100% !important;
    }
    .eventsHomePage .ellipseBlue{
        left: -600px !important;
    }
    .upcomingEvents{
        width: 100% !important;
        height: auto !important;
    }
    .upcomingEvents .eventsFrame{
        width: 100% !important;
        height: auto !important;
        margin-bottom: 50px !important;
    }
    .pastEventsContainer{
        width: 100% !important;
        padding: 0px 20px !important;
    }
    .pastEventsContainer .eventPageEvent{
        width: 100% !important;
        height: auto !important;
    }
    .section{
        width: 100% !important;
    }
    .section .sectionText{
        width: 100% !important;
        height: auto !important;
        margin: 15px !important;
        align-items: center !important;
    }
    .section .sectionText .eventsHeader{
        margin: 0px !important;
        text-align: center !important;
    }
    .upcomingEvents .eventsHeader, .eventsText{
        padding-right: 10px;
        padding-left: 10px;
    }
    .pastEventCardComponent:not(:last-child){
        margin-bottom: 10px;
    }
}
@media (max-width: 1000px){
    .eventsHomePage .ellipseBlue{
        left: -800px !important;
    }
    .eventsFrame{
        margin: 0px !important;
        padding: 0px 20px !important;
        gap: 20px !important;
    }
    .evntsArchive{
        margin-top: 80px;
        margin-bottom: 50px;
        padding: 0px 15px;
        gap: 0px;
    }

}
@media (max-width: 800px){
    .section{
        flex-direction: column !important;
        height: auto !important;
    }
    .section .sectionText{
        margin-top: 50px !important;
        padding: 10px;
    }
    .section .btn-want-to-make-see-upcoming-event{
        margin: 0px !important;
        margin-bottom: 50px !important;
        width: auto !important;
    }
}

@media (max-width: 650px){
    .eventsHomePage .joinAltoTextComponent{
        padding-left: 10px !important;
    }
    .evntsArchive .default-button{
        width: 100% !important;
    }
}
@media (max-width: 500px){
    .chips{
        overflow-x: scroll;
        justify-content: flex-start;
        padding-bottom: 20px;
        margin-bottom: 10px;
    }
}