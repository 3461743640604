.main-sign-in{
    width: 100%;
    height: auto !important;
    display: block;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
}
.main-sign-in .left-content{
    width: 55%;
    height: inherit;
    background: var(--Blue, #00529C);
    position: relative;
}
.main-sign-in .left-content img{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main-sign-in .right-content{
    position: relative;
    width: 45%;
    height: 735px;
    margin-right: auto;
    margin-left: auto;
}
.main-sign-in .right-content .form{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}
.main-sign-in .right-content .form .form-title h2{
    color: #222;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}
.main-sign-in .right-content .form .form-inner{
    width: 480px;
    padding: 40px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    margin-top: 40px;
}
.main-sign-in .right-content .form .form-inner .forgot-your-password .forgot-your-password-text{
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: 1px solid #00529C;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
}

.main-sign-in .right-content .form .form-inner .warning-box{
    width: 100%;
    padding: 16px 16px 16px 24px;
    border-radius: 16px;
    border-left: 8px solid var(--Red, #EB5757);
    background: rgba(235, 87, 87, 0.20);
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}
.main-sign-in .right-content .form .form-inner .warning-box .texts{
    text-align: start;
    margin-left: 10px;
}
.main-sign-in .right-content .form .form-inner .warning-box .texts .warning-title{
    color: #EB5757;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.main-sign-in .right-content .form .form-inner .warning-box .texts .warning-description{
    color: #EB5757;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
}
.main-sign-in .right-content .form .form-inner .btn-reset-password{
    width: 100% !important;
}
@media (max-width: 1500px){
    .main-sign-in{
        width: 100%;
    }
}
@media (max-width: 1300px){
    .main-sign-in .left-content{
        width: 45% !important;
    }
    .main-sign-in .left-content img{
        width: 250px;
        height: 90px;
    }
}
@media (max-width: 900px){
    .main-sign-in{
        flex-direction: column !important;
        width: 100% !important;
        height: 900px;
    }
    .main-sign-in .left-content{
        width: 100% !important;
        height: 250px !important;
    }
    .main-sign-in .right-content{
        width: 100% !important;
    }
    .main-sign-in .left-content img{
        width: 250px;
        height: 90px;
    }
    .main-sign-in .right-content .sign-in-form-container{
        margin-top: -80px !important;
    }
    .main-sign-in .right-content .reset-password-email-form-container{
        margin-top: -130px;
    }
}
@media (max-width: 600px){
    .main-sign-in .right-content{
        width: 95% !important;
    }
    .main-sign-in .right-content .form{
        width: 100% !important;
    }
    .main-sign-in .right-content .form .form-inner{
        width: 100% !important;
        padding: 20px 15px;
    }
}
@media (max-width: 450px){
    .main-sign-in .right-content .form .form-inner .bottom-buttons{
        flex-direction: column !important;
        gap: 20px;
    }
    .main-sign-in .right-content .form .form-inner .bottom-buttons button{
        width: 100% !important;
        margin: 0px !important;
    }
}