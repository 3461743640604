

@mixin breakpoint($point) {
    @if $point == md {
        // 768px
        @media (min-width: 1055px) {
            @content;
        }
    }
}

        .header .header__content{
            width: 95%;
        }
        .header__content__nav{
            width: 100% !important;
        }
        .header__content__nav .mainList:nth-child(1){
            margin-right: auto;
            margin-left: 80px;
            height: 100% !important;
        }
        .header__content__nav .mainList:nth-child(2){
            margin-left: auto;
            height: 100% !important;
        }
        .header .header__content nav .mainList .mainItem {
            display: flex;
            align-items: center;
        }
        .header .header__content nav .mainList .mainItem .mainItemLink{
            display: flex !important;
            flex-direction: row !important;
            align-items: center;
            width: 100% !important;
            height: 100px !important;
        }
        
        .header .header__content nav .mainList .mainItem .rightIcon{
            margin-left: auto !important;
        }
        .header .header__content nav .mainList .mainItem .rightIcon img{
            display: block;
            margin-right: auto;
            margin-left: auto;
            width: 10px;
        }
        .header__content__toggle .close-icon{
            position: fixed !important;
            top: 30px !important;
            right: 20px !important;
        }
        .header {
            top: 0;
            left: 0;
            z-index: 200;
            background: var(--White, #FFF);
            width: 100%;
            height: 100px;
            padding: 0 1.5rem;
            @include breakpoint(md) {
                padding: 0 3rem;
            }
            transition: 0.3s ease all;
        
            &__content {
                color: #00529C;
                margin: 0 auto;
                max-width: 1920px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                z-index: 100;
        
                &__logo {
                    font-size: 2rem;
                    font-weight: bold;
                    text-decoration: none;
                    color: #fff;
                }
        
                &__nav {
                    top: 0;
                    right: 100%;
                    bottom: 0;
                    width: 100%;
                    height: 100vh;
                    position: fixed;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: rgb(255, 255, 255);
                    backdrop-filter: blur(2px);
                    transform: translate(0);
                    transition: 0.3s ease transform;
                   
        
                    @include breakpoint(md) {
                        transform: none;
                        flex-direction: row;
                        background: transparent;
                        width: auto;
                        height: 100%;
                        position: static;
                    }
                    .mainList {
                        list-style: none;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 32px;
                        @include breakpoint(md) {
                            flex-direction: row;
                            align-items: center;
                            margin-bottom: 0;
                            margin-right: calc(0.5rem + #{16px});
                        }
                        .mainItem {
                            &:not(:last-child) {
                                margin-bottom:32px;
                                @include breakpoint(md) {
                                    margin-bottom: 0;
                                    margin-right: 16px;
                                }
                            }
                            a {
                                text-decoration: none;
                                color: inherit;
                                padding: 38px 1.25rem;
                                transition: 0.3s ease all;
        
                                &:hover {
                                    background: rgba(#fff, 0.1);
                                }
                                &:active {
                                    border-radius: calc(#{12px} + 6px);
                                    background: linear-gradient(rgba(#fff, 0.1), rgba(#fff, 0.2));
                                }
                            }
                        }
                    }
                    .btn {
                        cursor: pointer;
                        outline: none;
                        padding: 0.75rem 1.25rem;
                        border-radius: 12px;
                        font-size: 1rem;
                        font-family: inherit;
                        background: rgb(162, 162, 246);
                        color: rgb(32, 32, 32);
                        border: 1px solid transparent;
                        transition: 0.3s ease all;
                        margin:0.50em 0.50em;
                       
        
                        &:hover {
                            border-color: rgb(162, 162, 246);
                            background: rgba(rgb(162, 162, 246), 0.1);
                            color: rgb(162, 162, 246);
                        }
                        &:active {
                            border-color: rgb(162, 162, 246);
                            background: linear-gradient(rgba(rgb(162, 162, 246), 0.2), rgba(rgb(162, 162, 246), 0.3));
                            color: rgb(162, 162, 246);
                        }
                        &__login{
                            
                            background-color: rgb(32, 32, 32);
                            color:#fff;

                        }
                        
                    }

        
                    &.isMenu {
                        transform: translate(100%);
                    }
                }
        
                &__toggle {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    font-size: 2rem;
                    transition: 0.3s ease all;
                    position: relative;
        
                    &:hover {
                        color: rgb(162, 162, 246);
                    }
        
                    @include breakpoint(md) {
                        display: none;
                    }
                }
            }
        }

@media (max-width: 1300px) {
    .header{
        width: 100% !important;
        padding: 0px 20px;
    }
    .header .header__content{
        width: 100% !important;
    }
    .header__content__nav .mainList:nth-child(1){
        margin-left: 20px !important;
    }
}
@media (max-width: 1055px){
    .header__content__nav{
        height: 5000px !important;
    }
    .header__content__nav .mainList:nth-child(1), .header__content__nav ul:nth-child(2){
        margin-right: auto !important;
        margin-left: auto !important;
        width: 100% !important;
        text-align: start;
    }  
    .header__content__nav .mainList:nth-child(1){
        margin-top: 50px !important;
        height: auto !important;
    }
    .header__content__nav .mainList .mainItem{
        border-bottom: 1px solid rgba(34, 34, 34, 0.10);
        padding-bottom: 10px;
        margin: 0px !important;
        color: var(--Black, #222);
        font-family: DM Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0px !important;
        margin: 0px !important;
    }

    .header .header__content nav .mainList .mainItem .mainItemLink{
        height: 50px !important;
    }
    .header__content__nav .mainList:nth-child(2){
        margin-top: -32px !important;
    }
    .header__content__nav .mainList:nth-child(2) li:last-child{
        border-bottom: none !important;
    }
    .header .header__content nav .mainList .mainItem .subList{
        width: 100% !important;
    }
    .header .header__content nav .mainList .mainItem .rightIcon{
        display: block !important;
        width: 50px;
        height: 50px;
    }
    .header .header__content nav .mainList .mainItem .rightIcon img{
        margin-top: 18px;
    }
    .search-and-close-icon{
        display: none;
    }
}