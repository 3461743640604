.contactUsComponentMain{
    width: 100%;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(../images/homepage/footer-hero.jpg), lightgray 50% / cover no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}
.contactUsComponentMain .title{
    color: var(--white, #FFF);
    text-align: center;
    font-family: DM Sans;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 50px;
}
.contactUsComponentMain .description{
    color: var(--White, #FFF);
    text-align: center;
    font-family: DM Sans;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
}

.contactUsComponentMain .form{
    width: 1000px;
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 40px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.94); 
    margin-top: 60px;
}
.contactUsComponentMain .form .formGroup{
    margin-bottom: 20px;
    text-align: start;
    width: 100%;
}
.contactUsComponentMain .form .formGroup input{
    width: 100%;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #BDBDBD;
    padding-bottom: 5px;
}
.contactUsComponentMain .form .formGroup .inputTitle{
    color: var(--black, #222);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contactUsComponentMain .form .firstNameAndLastName{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.contactUsComponentMain .form .formGroup:not(:last-child){
    margin-bottom: 40px;
}

.contactUsComponentMain .form .btnSend{
    width: 100%;
    padding: 12px 40px;
    border-radius: 8px;
    background: var(--blue, #00529C);
    color: var(--white, #FFF);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 1500px) {
    .contactUsComponentMain{
        width: 100% !important;
    }
}
@media (max-width: 1100px) {
    .contactUsComponentMain .form{
        width: 95% !important;
    }
}
@media (max-width: 650px) {
    .contactUsComponentMain{
        padding: 0px 10px 50px 10px;
    }
    .contactUsComponentMain .form{
        width: 95% !important;
        padding: 30px !important;
    }
    .contactUsComponentMain .form .firstNameAndLastName{
        flex-direction: column !important;
        gap: 0px;
        margin-bottom: 20px;
    }
}