.homePage_container{
    width: 100%;
    background-color: #F4F4F4;
}
.homePage_container .homePageContainerInner{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0px 100px 0px;
    gap: 60px;
    margin: 0px auto;
    width: 100%;
    position: relative;
}
.image_courosel{
    left: 0px;
    z-index: 100;
    width: 1440px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    gap: 20px;
}
.homePageContainerText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-end;
    padding: 0px 80px;
    gap: 40px;

    width: 100%;
}
.pageHeaderText{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 96px;
    color: #222222;
    text-align: right;
}
.pageHeaderInlineText{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #222222;
}
.homepageButtons{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 19.78px;
    width: auto;
    height: 47px;
    margin: 0px;
}
.blueButton{
    padding: 12px 40px;
    gap: 8px;
    min-width: 270px;
    width: auto;
    background: #00529C;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}
.whiteButton{
    padding: 12px 40px;
    gap: 10px;
    width: auto;
    border: 1px solid #00529C;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #00529C;
}
.whiteButtonJoinAlto{
    width: 280px;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--White, #FFF);
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    outline: none !important;
    box-shadow: none !important;
}
.homePageContainerInner .ellipse{
    position: absolute;
    top: 500px;
    left: -700px;
    width: 1305px;
    height: 1000px;
    background: rgba(0, 82, 156, 0.08);
    border-radius: 50%;
    flex-shrink: 0;
}
.homePageaboutUs{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    gap: 40px;
    width: 100%;
    background: #FFFFFF;
    overflow: hidden;
    align-items: center;
    gap: 60px;
    margin: 0px auto;
}
.homepageaboutUsImage{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 108px;
    width: 100%;
    background: linear-gradient(92.01deg, rgba(0, 82, 156, 0.8) 0.97%, rgba(255, 255, 255, 0.9) 100%);
    background-blend-mode: normal, darken;
    border-radius: 0px 16px 16px 16px;
}
.homepageaboutUsText{
    font-family: 'DM Sans';
    font-style: normal;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    /* White */
    color: #FFFFFF;
}
.headerStyle{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;
}
.boxContainer{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 20px;
    width: 610px;
    height: 330px;
    background: #FFFFFF;
    border: 2px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}
.boxParagraph{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #222222;
}
.homePageMemories{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 0px auto;
    align-items: center;
    width: 100%;
}
.homePageSection1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    border-radius: 40px 40px 0px 0px;
}
.homePageSection2{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    gap: 60px;
    width: 100%;
    height: auto;
    border-radius: 40px 40px 0px 0px;
    position: relative;
    background-color: #FFFFFF;
}
.homePageAltoMemoryCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 0px 0px 80px;
    gap: 40px;
    width: 1100px;
    height: 408px;
}
.divStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 56px 80px;
    gap: 67px;
    background: #00529C;
}
.ellipse2{
    position: relative;
    width: 555px;
    height: 555px;
    background: rgba(224, 224, 224, 0.92);
    border-radius: 50%;
    top: -520px;
    z-index: 50;
}
.homePageAltoText{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    gap: 50px;
    top: -220px;
    position: relative;
    width: 657px;
    height: 239px;
    left: calc(50% - 657px/2 + 0.5px);
    bottom: 0.09px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}
.homePageBecomeMember{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    gap: 60px;
    margin: 0px auto;
    width: 1440px;
    height: 559.2px;
    background: #FFFFFF;
}
.homePageBecomeMemberDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 40px;
    width: 646.29px;
    height: 325.2px;
}
.joinAltoDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}
.joinAltoDiv .joinAltoDivEllipseBlue{
    width: 1305px;
    height: 1000px;
    position: absolute;
    left: -320px;
    bottom: -305px;
    border-radius: 1305px;
    background: var(--50-blue, rgba(0, 82, 156, 0.50));
}
.joinAltoTextComponent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
}
.joinAltoHeader1{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 96px;
}
.joinAltoHeader2{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    align-items: center;
}
.joinAltoDiv2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    margin: 0px auto;
    border-radius: 40px 40px 0px 0px;
    margin-top: 0px;
}
.sampleDiv{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
}
.circle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 80px;
    height: 80px;
    /* White */
    background: #FFFFFF;
    /* Shadow 1 */
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 80px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #222222;
}
.dashedLine{
    position: relative;
    width: 930px;
    height: 0px;
    right: 155px;
    left: 10px;
    top: 80px; 
    border: 2px dashed white;
}
.blueBoxContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 20px;
    width: 400px;
    /* Dark-Light Blue Gradient */
    background: linear-gradient(46.28deg, #00529C 1.09%, #0077B5 100%);
    border-radius: 16px;
}
.borderBox{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 40px;
    gap: 10px;
    width: 777px;
    background: #FFFFFF;
    border: 1px solid rgba(34, 34, 34, 0.12);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-bottom: 70px;
}
.vectorBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 40px;
    gap: 20px;
    width: 400px;
    border-radius: 16px;
}
.commitBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}
.divider{
    width: 100%;
    height: 1px;
    margin-top: 10px;
    background:#222222 ;
    opacity: 0.4;
}
.offerBox .inner{
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(34, 34, 34, 0.12);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 100% !important;
}
.headings{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 0px;
    gap: 20px;
    margin-left: 40px;
    margin-right: 40px;
}
.headings p:nth-child(1){
    text-align: start;
}
.headings p:nth-child(2){
    text-align: center;
}
.headings p:nth-child(3){
    margin-left: auto;
}
.blueLine{
    width: 1160px;
    height: 1px;
    background: #00529C;
    margin-left: 40px;
}
.totalDiv{
    display: flex;
    padding: 20px 40px;
    width: 100%;
    background: #00529C;
    border-bottom: 1px solid #00529C;
    border-radius: 0px;
    color: white;
}
.totalDiv .totalDescription{
    text-align: start;
    width: fit-content;
}
.totalDiv .totalAmount{
    margin-left: auto;
    font-weight: 600;
}
.layOutDiv{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 40px 80px; */
    gap: 40px;
}
.altoBoard{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 80px;
    gap: 40px;
    margin: 0px auto;
    position: relative;
    width: 100%;
}
.peopleCardComponent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    overflow: hidden;
    width: 413.33px;
    height: 629px;
    /* White */
    background: #FFFFFF;
    border: 1px solid #00529C;
    border-radius: 16px;
}
.cardBoard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    margin-top: 40px;
    width: 1280px;
    height: 629px;
}
.altoMemories{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0px;
    gap: 40px;
    margin: 0px auto;
    position: relative;
    background: #FFFFFF;
}
.altoFrame{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 80px 56px;
    gap: 40px;
    overflow: hidden;
    height: auto !important;
}
.altoFrameCard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 346.67px;
    height: auto !important;
}
.profilePhoto{
    box-sizing: border-box; 
    border-radius: 50%;
    
    /* margin-left: 570px;
    margin-top: 60px; */
    /* position: relative; */
    width: 320px;
    height: 320px;
    /* left: 0px;
    top: -0.12px;
    border: 4.8855px solid #FFFFFF; */
}
.altoComment{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 40px;
    width: 1280px;
}
.frameContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}
.avatar{
    width: 1440px;
    height: 975px;
    background: #FFFFFF;
}
.quoteArea{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 1280px;
    height: 115px;
}
.frameContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 80px 0px 80px;
    width: 100%;
}
.profilePicture{
    width: 320px;
    height: 410px;  
}
.avatar{
    width: 320px;
    height: 320px;
}

.w-100{
    width: 100% !important;
}
.w-25{
    width: 25%;
}
.w-30{
    width: 30%;
}
.w-40{
    width: 40%;
}
.w-45{
    width: 45%;
}
.m-r-auto{margin-right: auto;}
.m-l-auto{margin-left: auto;}
.m-l-r-auto{margin-left: auto; margin-right: auto;}
.display-flex{display: flex;}
.text-left{text-align: left;}
.text-right{text-align: end;}

.cursor-pointer{
    cursor: pointer;
}

.default-button{
    width: 100% !important;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Blue, #00529C);
    color: var(--White, #FFF);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.default-button-white{
    width: auto;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #FFFFFF;
    color: #00529C;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.outlined-button{
    width: auto;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #00529C;
    color: var(--White, #00529C);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.disable-button{
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba(189, 189, 189, 0.30);
    color: #7e7e7e;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.form .formGroup{
    margin-bottom: 20px;
    text-align: start;
    width: 100%;
}
.form .formGroup input{
    width: 100%;
    margin-top: 5px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #BDBDBD;
    padding-bottom: 5px;
}
.form .formGroup .inputTitle{
    color: var(--black, #222);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form .firstNameAndLastName{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.form .formGroup:not(:last-child){
    margin-bottom: 40px;
}
.check-box-box{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0px;
}
.check-box-box input[type='checkbox']{
    width: 20px;
    height: 20px;
    box-shadow: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
}
.check-box-box label{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
    cursor: pointer;
}
.rich-text-content{
    text-underline-offset: 5px;
    white-space: pre-line !important;
}

@media (max-width: 1500px){
    .joinAltoDiv .joinAltoDivEllipseBlue{
        left: -600px !important;
    }
    .joinAltoDiv .joinAltoTextComponent {
        width: 500px;
    }
    .joinAltoDiv .joinAltoTextComponent .joinAltoHeader1{
        width: 100% !important;
        text-align: start;
    }
}
@media (max-width: 1000px){
    .joinAltoDiv .joinAltoDivEllipseBlue{
        left: -800px !important;
    }
    .joinAltoDiv .joinAltoTextComponent{
        padding-left: 10px !important;
    }
}
@media (max-width: 550px){
    .joinAltoDiv .joinAltoTextComponent{
        width: 380px;
    }
}
@media (max-width: 400px){
    .joinAltoDiv .joinAltoTextComponent{
        width: auto;
    }
}