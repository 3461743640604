.eventsCardComponent:not(:last-child){
    margin-bottom: 40px !important;
}
.eventsCardComponentImage .passive-image{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.eventsCardComponent .default-button{
    width: 100% !important;
}

@media (max-width: 1500px){
    .eventsCardComponent{
        height: auto !important;
        margin: 0px !important;
    }
    .eventsCardComponent .eventsCardComponentImage{
        height: auto !important;
        width: 100% !important;
    }
    .eventsCardComponent .eventsCardComponentImage img{
        max-width: 100% !important;
        height: auto !important;
    }

}
@media (max-width: 1450px){
    .eventsCardComponent{
        width: 500px !important;
    }
}
@media (max-width: 1200px){
    .eventsCardComponent{
        width: 100% !important;
    }
}