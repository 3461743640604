.altoMemoryCard {
    width: 30%;
}
.altoMemoryCard .cardContent{
    text-align: start;
}
.altoMemoryCard .cardContent .title{
    color: #222222;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.altoMemoryCard .cardContent .dateText{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 10px;
}
.altoMemoryCard .cardContent .description p{
    overflow: hidden;
    color: var(--Black, #222);
    text-overflow: ellipsis;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    max-height: 95px;
}
.altoMemoryCard .cardContent .viewDetails span{
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: underline;
    text-underline-offset: 8px;
    cursor: pointer;
}

@media (max-width: 1065px) {
    .altoMemoryCard {
        width: 45%;
        padding: 0px;
    }
    .altoFrame{
        padding: 0px;
    }
}

@media (max-width: 700px) {
    .aboutUsPage .altoFrame{
        display: flex;
        flex-direction: column;
        width: 100% !important;
        height: auto !important;
        padding: 0px !important
    }
    .altoMemoryCard {
        width: 100%;
    }
}