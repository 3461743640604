.frameContainer .userName{
    margin-top: 30px;
    margin-bottom: 10px;
}
.frameContainer .userName p{
    color: var(--Blue, #00529C);
    text-align: center;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.frameContainer .title p{
    color: var(--Black, #222);
    text-align: center;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.frameContainer .userInfo{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    top: -70px;
}
.frameContainer .userInfo .leftArrow{
    margin-left: auto;
}

.frameContainer .userInfo .rightArrow{
    margin-right: auto;
}

@media (max-width: 1500px){
    .frameContainer, .userInfo{
        width: 1200px !important;
        height: auto !important;
        padding: 0px !important;
    }
    .frameContainer .frameContent{
        width: 100% !important;
        height: auto !important;
        padding-left: 0px;
        padding-right: 0px;
    }
    .frameContainer .frameContent .userInfo .userInfoName{
        width: 100% !important;
    }
    .user-info-description-dd{
        display: block !important;
        margin-right: auto !important;
        margin-left: auto !important;
        width: 100% !important;
    }

}
@media (max-width: 1200px){
    .frameContainer, .userInfo{
        width: 1000px !important;
    }
}

@media (max-width: 1000px){
    .frameContainer, .userInfo{
        width: 700px !important;
    }
}

@media (max-width: 700px){
    .frameContainer{
        width: 100% !important;
    }
    .frameContainer .userInfo {
        width: 500px !important;
        margin-top: 70px !important;
    }
    .frameContainer .frameContent .profilePicture{
        vertical-align: middle !important;
        height: auto !important;
    }
    .frameContainer .frameContent .profilePicture img{
        width: 200px;
        height: 200px;
        margin-right: auto;
        margin-left: auto;
    }
}
@media (max-width: 500px){
    .frameContainer .userInfo{
        width: 300px !important;
    }
}
@media (max-width: 450px){
    .frameContainer{
        width: 300px !important;
    }
    .frameContainer .frameContent .profilePicture img{
        width: 150px;
        height: 150px;
    }
    
}
