.news-card-main .newComponent{
    display: flex;
    flex-direction: row;
    padding: 0px 24px 0px 0px;
    gap: 20px;
    width: 100%;
    height: auto !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;
}
.news-card-main .newImage{
    width: 40%;
    height: auto;
}
.news-card-main .newImage img{
    border-radius: 16px 0px 0px 16px;
}
.news-card-main .newText{
    text-align: left;
    padding: 24px 0px;
    width: 100%;
}
.news-card-main .newCardHeader{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    text-align: left;
    color: #00529C;
}
.news-card-main .textArea{
    height: 154px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
}
.news-card-main .newsCardText{
    overflow: hidden;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #222222;
    white-space: pre-line;
}
.news-card-main .readMoreButton{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    /* Blue */
    color: #00529C;
}
.news-card-main .newsButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    gap: 8px;
    width: 280px;
    height: 47px;
    /* Blue */
    color:white;
    background: #00529C;
    border-radius: 8px;
}

@media (max-width: 1500px){
    .news-card-main {
        width: 100% !important;
        height: auto !important;
    }
    .news-card-main .newComponent{
        width: 95% !important;
    }
}
@media (max-width: 1350px){
    .news-card-main .newComponent{
        flex-direction: column !important;
        padding: 20px !important;
    }
    .news-card-main .textArea{
        width: 100% !important;
    }
    .news-card-main .newsCardText{
        text-align: start !important;
    }
}

@media (max-width: 550px){
    .news-card-main .newImage{
        width: 100% !important;
        height: auto !important;
    }
}