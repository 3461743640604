.navigation-main{
    width: 100%;
    text-align: start;
}
.navigation-main .navigation-main-inner {
    padding: 55px 0px;
} 
.navigation-main .navigation-main-inner .title{
    color: #222;
    font-size: 28px;
    font-weight: 700;
}
.navigation-main .navigation-main-inner .content{
    margin-top: 45px;
}
.navigation-main .navigation-main-inner .content .items{
    display: flex;
    flex-direction: row;
    gap: 40px;
    overflow: scroll;
}
.navigation-main .navigation-main-inner .content .items::-webkit-scrollbar {
    display: none;
}
.navigation-main .navigation-main-inner .content .items .item{
    width: 345px;
}
.navigation-main .navigation-main-inner .content .items .item .image img{
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.navigation-main .navigation-main-inner .content .items .item .date-value p{
    color: #222;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
}
.navigation-main .navigation-main-inner .content .items .item .data .title{
    margin-top: 10px;
    color: #00529C;
    font-size: 28px;
    font-weight: 700;
    cursor: pointer;
}
.navigation-main .navigation-main-inner .content .items .item .data .description{
    color: #222;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    max-height: 100px;
    overflow: hidden;
}
.navigation-main .navigation-main-inner .content .items .item .data .default-button{
    margin-top: 30px;
}

@media (max-width:800px){
    .navigation-main .navigation-main-inner {
        padding: 55px 0px;
    } 

}