.footerComponentMain {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 30px 80px;
    border-top: 1px solid #EAEAEA;
    background: #FFF;
    align-items: center; 
}

.footerComponentMain .footer-copyright-and-socials p {
    margin: 0;
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.footerComponentMain .footer-copyright-and-socials p span {
    color: #00529C;
    cursor: pointer;
}

.footerComponentMain .footer-copyright-and-socials .social-links {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.footerComponentMain .footer-powered-by {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}

.footerComponentMain .footer-powered-by p {
    color: #808080;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
}

.footerComponentMain .footer-powered-by img {
    width: 80%;
    cursor: pointer; 
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1200px) {
    .footerComponentMain {
        padding: 30px 20px;
    }
}

@media (max-width: 950px) {
    .footerComponentMain {
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 20px;
    }

    .footerComponentMain .footer-copyright-and-socials .social-links {
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .footerComponentMain .footer-powered-by p {
        text-align: center;
    }

    .footerComponentMain .footer-powered-by {
        margin: 0;
    }

    .footerComponentMain .footer-powered-by img {
        width: 50%; 
        margin: 0 auto;
    }
}
