.newsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    width: 610px;
    text-align: start;
}
.newsHeader{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 96px;
}
.default-button{
    width: 350px !important;
}
.newsAndProjectnewsCardComponent{
    width: 100%;
    padding: 56px 80px;
    background: #FFFFFF;
}
@media (max-width: 1500px) {
    .news-and-projects-main .joinAltoDiv{
        width: 100% !important;
    }
    .news-and-projects-main .newsAndProjectnewsCardComponent{
        padding: 0px !important;
        margin-top: 40px;
    }

}
@media (max-width: 1000px) {
    .news-and-projects-main .newsContainer{
        width: 100%;
        padding: 0px !important;
        padding-left: 15px !important;
    }
}