.trends-barometer{
    width: 100%;
    margin-bottom: 40px;
}
.trends-barometer .trends-barometer-main{
    padding: 50px 0px 50px 80px;
    display: flex;
    flex-direction: row;
}
.trends-barometer .trends-barometer-main .left-section{
    width: 52%;
    text-align: start;
}
.trends-barometer .trends-barometer-main .right-section{
    width: 45%;
    text-align: end;
    margin-left: auto;
}

.trends-barometer .trends-barometer-main .left-section .title p{
    color: #222;
    font-size: 48px;
    font-weight: 700;
}
.trends-barometer .trends-barometer-main .left-section .sub-title p{
    color:#00529C;
    font-size: 48px;
    font-weight: 700;
}
.trends-barometer .trends-barometer-main .left-section .description{
    color: #000;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 40px;
}
.trends-barometer .trends-barometer-main .left-section .please-complete-button{
    margin-top: 40px;
}
.trends-barometer .trends-barometer-main .left-section .please-complete-button button{
    width: fit-content !important;
}
.trends-barometer .trends-barometer-main .left-section .links {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 40px;
}
.trends-barometer .trends-barometer-main .left-section .links a{
    color: #00529C;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 7px;
}
.trends-barometer .trends-barometer-main .right-section .image-content{
    display: flex;
}
.trends-barometer .trends-barometer-main .right-section .image-content img{
    margin-left: auto;
}
.trends-barometer .sub-table{
    width: 100%;
}
.trends-barometer .sub-table .content:nth-child(even){
    background: #FFF;
}
.trends-barometer .sub-table .content:nth-child(odd){
    background: rgba(0, 82, 156, 0.04);
}
.trends-barometer .sub-table .content .content-inner{
    display: flex;
    flex-direction: row;
    gap: 30px;
    text-align: start;
    padding: 50px 80px;
}
.trends-barometer .sub-table .content .left-content{
    width: 50%;
}
.trends-barometer .sub-table .content .left-content p{
    color: #222;
    font-size: 28px;
    font-weight: 700;
}
.trends-barometer .sub-table .content .right-content{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.trends-barometer .sub-table .content .right-content p{
    color: #000;
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 1000px){
    .trends-barometer .trends-barometer-main {
        padding: 0px !important;
        margin-top: 40px;
        flex-direction: column-reverse !important;
        margin-bottom: 40px;
    }
    .trends-barometer .trends-barometer-main .left-section, .trends-barometer .trends-barometer-main .right-section{
        width: 100%;
    }
    .trends-barometer .trends-barometer-main .left-section{
        padding: 0px 20px;
        margin-top: 40px;
    }   
}
@media (max-width: 800px) {
    .trends-barometer .sub-table .content .content-inner{
        flex-direction: column;
        padding: 50px 15px;
    }
    .trends-barometer .sub-table .content .left-content, .trends-barometer .sub-table .content .right-content{
        width: 100%;
    }

}
@media (max-width: 485px){
    .trends-barometer .trends-barometer-main .left-section .title p{
        font-size: 32px;
    }
    .trends-barometer .trends-barometer-main .left-section .sub-title p{
        font-size: 32px;
    }
}