.contactContainer{
    width: 100%;
    position: relative;
    margin: 0px auto;
    background: #FFFFFF;
}
.contactHeaderContainer{
    padding: 40px 0px 0px 80px;
    width: 100% !important;
}
.contactHeader{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 96px;
    display: flex;
    align-items: center;
    color: #222222;
}
.cantactInlineHeader{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #222222;

}
.contactImage{
    margin-top: 30px;
    width: 100%;
    height: 898px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    background-size: cover !important; 
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-clip: content-box !important;
    display: flex;
}
.contactImage .form{
    padding: 40px;
    width: 500px;
    height: 780px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    margin-left: auto;
    margin-right: 65px;
    margin-top: -180px !important;
}
.contactImage .form .default-button{
    width: 100% !important;
}
@media (max-width: 1500px){
    .contactContainer, .contactHeaderContainer, .contactHeader{
        width: 100% !important;
        height: auto !important;
    }
    .contactImage{
        width: 100% !important;
        
    }
}
@media (max-width: 1380px){
    .contactHeaderContainer{
        padding-left: 20px !important;
    }
    .contactHeaderContainer{
        width: 50% !important;
        text-align: start !important;
    }
}
@media (max-width: 1145px){
    .contactHeaderContainer{
        width: 100% !important;
    }
    .contactImage{
        margin-top: 380px !important;
    }
    .contactImage .form{
        margin-top: -330px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

}
@media (max-width: 550px){
    .contactImage .form{
        width: 95% !important;
        padding: 40px 20px !important;
    }
    .contactHeaderContainer .contactHeader{
        font-size: 50px !important;
    }
    .contactHeaderContainer .cantactInlineHeader{
        font-size: 20px !important;
    }
}