.joinNowMain .joinNowMainInner{
    width: 100% !important;
    margin-right: auto;
    margin-left: auto;
}
.joinNowMain .joinNowMainBanner{
    overflow: hidden;
}
.joinNowMain .joinNowMainBanner .joinAltoTextComponent .joinAltoHeader1{
    width: 970px !important;
    text-align: left;
}
.joinNowMain .joinNowMainBanner .joinAltoTextComponent .joinAltoHeader2{
    width: 370px !important;
    text-align: left;
}
.joinNowMain .joinNowMainBanner .bottom-buttons{
    margin-top: 20px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 40px;
}
.joinNowMain .joinNowMainBanner .bottom-buttons button:nth-child(1){
    width: 280px;
}
.joinNowMain .download-the-registration-form{
    margin-top: 55px;
    padding: 0px 20px;
}
.joinNowMain .download-the-registration-form p:nth-child(1){
    color: var(--Black, #222);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
}
.joinNowMain .download-the-registration-form p:nth-child(2){
    margin-bottom: 40px;
}
.joinNowMain .items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 100px;
    justify-content: center;
    gap: 105px;
    padding: 0px 20px !important;
}
.joinNowMain .items .item {
    width: 400px;
}
.joinNowMain .items .item .image{
    margin-bottom: 15px;
}
.joinNowMain .items .item .image img{
    max-width: 100%;
    width: 100%;
    min-height: 230px;
    max-height: 230px;
    height: auto;
    border-radius: 32px 8px 8px 8px !important;
}
.joinNowMain .items .item .link a{
    color: #00529C;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: underline;
    cursor: pointer;
}
.blueBoxContainers::-webkit-scrollbar{
    display: none;
}
@media (max-width: 1300px) {
    .joinNowMain .items{
        gap: 20px;
    }
}
@media (max-width: 1100px) {
    .joinNowMain .joinNowMainBanner .joinAltoTextComponent .joinAltoHeader1{
        width: 800px !important;
    }
}
@media (max-width: 830px) {
    .joinNowMain .joinNowMainBanner .joinAltoTextComponent .joinAltoHeader1{
        width: 600px !important;
    }
}
@media (max-width: 700px) {
    .joinNowMain .items{
        flex-direction: column;
        gap: 30px;
    }
    .joinNowMain .items .item{
        width: 100% !important;
        height: auto !important;
    }
    .joinNowMain .items .item .image img{
        min-height: auto !important;
        max-height: auto !important;
        width: 100% !important;
        height: auto !important;
    }
    .joinNowMain .items .item .image img{
        margin-right: auto !important;
        margin-left: auto !important;
    }
}
@media (max-width: 550px) {
    .joinNowMain .joinNowMainBanner .joinAltoTextComponent .joinAltoHeader1{
        width: 400px !important;
    }
    .joinNowMain .joinNowMainBanner .joinAltoTextComponent .bottom-buttons{
        flex-direction: column !important;
        gap: 20px;

    }
}