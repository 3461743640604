.joinAltoDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
    position: relative;
    margin: 0px auto;
}
.joinAltoTextComponent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.joinAltoHeader1{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 96px;
}
.joinAltoHeader2{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    align-items: center;
}
.why-should-join-alto-items-dart-box{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    gap: 30px;
    justify-content: center;
}
.why-should-join-alto-items-dart-box .why-should-join-alto-items-dart-box-vectorBox{
    width: 400px;
    justify-content: center;
}
.why-should-join-alto-items-dart-box .why-should-join-alto-items-dart-box-vectorBox img{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
}
.membershipCriteria{
    position: relative;
    margin-top: -50px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    background: var(--White, #FFF);
    padding: 55px 80px;
}
.membershipCriteria h2{
    color: #222;
    font-family: DM Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.membershipCriteria .description-h2{
    color: var(--Black, #222);
    text-align: center; 
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 40px 0px;
}
.membershipCriteria .items {
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.membershipCriteria .items .item .imageAndDescription{
    display: flex;
    flex-direction: row;
    gap: 40px;
}
.membershipCriteria .items .item .imageAndDescription .membershipCriteriaImage{
    width: 25%;
}
.membershipCriteria .items .item .imageAndDescription .membershipCriteriaImage img{
    width: 100% !important;
    height: auto !important;
    max-height: 250px;
    max-width: 100% !important;
    border-radius: 32px 8px 8px 8px;
}
.membershipCriteria .items .item .membershipCriteriaImage .title{
    margin-top: 10px;
}
.membershipCriteria .items .item .imageAndDescription .membershipCriteriaDescription{
    width: 75%;
    align-self: flex-end;
    margin-bottom: 60px;
}
.membershipCriteria .items .item .title h3{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}
.membershipCriteria .items .item .imageAndDescription .membershipCriteriaDescription p{
    text-align: start;
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.membershipCriteria .items .item .imageAndDescription .bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}
.membershipCriteria .items .item .imageAndDescription .bottom a{
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    align-items: center;
}
.membershipCriteria .items .item .imageAndDescription .bottom a span{
    font-size: 20px;
    font-weight: 300;
    margin-left: 10px;
}
.membershipCriteria .items .item .imageAndDescription .bottom button{
    width: 240px;
    padding: 12px 40px;
    gap: 8px;
    border-radius: 8px;
    background: var(--Blue, #00529C);
    color: var(--White, #FFF);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: auto;
}
.join-alto-main .commitBox{
    margin-right: auto;
    margin-left: auto;
}
.join-alto-main .offerBox {
    margin-right: auto;
    margin-left: auto;
}
.join-alto-main .offerBox .tab-buttons{
    margin: 40px 0px 30px 0px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.join-alto-main .offerBox .tab-buttons .tab{
    width: fit-content;
    cursor: pointer;
    border-bottom: 1px solid #00529C;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
}
.join-alto-main .offerBox .tab-buttons .passive-tab{
    border: none !important;
}
.join-alto-main .offerBox .tab-buttons .tab span{
    color: var(--Blue, #00529C);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.join-alto-main .offerBox .tab-buttons .passive-tab span{
    color: #6696c4;
}
.join-alto-main .offerBox .headings-row{
    width: 100% !important;
    display: flex;
    flex-direction: row;
}
.join-alto-main .offerBox .headings-column{
    display: flex;
    flex-direction: row;
    width: 100% !important;
    text-align: start;
    margin-top: 30px !important;
    border-bottom: 1px solid #00529C;
    padding-bottom: 10px !important;
}
.join-alto-main .offerBox .passive p, .passive a{
    color: #BDBDBD !important;
}
.join-alto-main .offerBox .headings-column .special-offer-description{
    margin-right: auto;
    width: 70%;
    text-align: start;
    font-size: 15px;
    font-weight: 400;
}
.join-alto-main .offerBox .headings-column .special-offer-description .description{
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
}
.join-alto-main .offerBox .headings-column .special-offer-expires{
    width: 15%;
    color: var(--Black, #222);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.join-alto-main .offerBox .headings-column .special-offer-description .title{
    color: var(--Blue, #00529C);
    font-size: 18px;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
}
.join-alto-main .offerBox .headings-column .special-offer-savings{
    width: 15%;
    margin-left: auto;
    text-align: end;
    color: var(--Black, #222);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.join-alto-main .offerBox .headings-row h1:nth-child(1){
    margin-right: auto;
    width: 70%;
    text-align: start;
}
.join-alto-main .offerBox .headings-row h1:nth-child(2){
    margin-right: auto;
    margin-left: auto;
    width: 15%;
    text-align: start;
}
.join-alto-main .offerBox .headings-row h1:nth-child(3){
    margin-left: auto;
    width: 15%;
    text-align: end;
}
.join-alto-main .offerBox .headings h1{
    width: 100%;
}
.howtoJoinAltoJoinAltoDiv .howtoJoinAltoJoinAltoDivSampleDiv{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100% !important;
    justify-content: center;
    margin: 35px 0px 10px 0px;
    gap: 30px;
}
.howtoJoinAltoJoinAltoDiv .stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.howtoJoinAltoJoinAltoDiv .stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.howtoJoinAltoJoinAltoDiv .stepper-item .step-name{
    margin-top: 20px;
}
.howtoJoinAltoJoinAltoDiv .stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px dashed #fff;
  width: 100%;
  top: 40px;
  left: -50%;
  z-index: 2;
}

.howtoJoinAltoJoinAltoDiv .stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px dashed #fff;
  width: 100%;
  top: 40px;
  left: 50%;
  z-index: 2;
}

.howtoJoinAltoJoinAltoDiv .stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 6px;
  color: #00529C;
  font-size: 28px;
  font-weight: 700;
}
.howtoJoinAltoJoinAltoDiv .stepper-item:first-child::before {
  content: none;
}
.howtoJoinAltoJoinAltoDiv .stepper-item:last-child::after {
  content: none;
}

@media (max-width: 768px) {
    .howtoJoinAltoJoinAltoDiv .stepper-wrapper {
        flex-direction: column;
    }
    .howtoJoinAltoJoinAltoDiv .stepper-item {
        flex-direction: row;
        gap: 20px;
        flex: 1;
        text-align: start;
    }
    .howtoJoinAltoJoinAltoDiv .stepper-item::after {
        position: absolute;
        content: "";
        border-left: 2px dashed #ccc;
        width: 0px;
        height: 100%;
        left: 40px;
        z-index: 2;
        top: 50%
    }
    .howtoJoinAltoJoinAltoDiv .stepper-item::before {
        position: absolute;
        content: "";
        border-left: 2px dashed #ccc;
        width: 0px;
        height: 100%;
        left: 40px;
        z-index: 2;
        top: -50%
    }
}
@media (max-width: 1500px){
    .join-alto-main .joinAltoDiv{
        width: 100% !important;
    }
    .join-alto-main .joinAltoDiv2{
        width: 100% !important;
    }
    .join-alto-main .joinAltoDiv2 .why-should-join-alto{
        width: 100% !important;
        padding: 40px 15px !important;

    }
    .join-alto-main .commitBox{
        width: 100% !important;
    }
    .join-alto-main  .offerBox .inner {
        width: 100% !important;
    }
    .howtoJoinAltoJoinAltoDiv{
        padding: 40px 15px !important;
    }
    .join-alto-main .blueBoxContainers{
        flex: auto !important;
        width: 100% !important;
        overflow-x: scroll;
        justify-content: center !important;

    }
    .join-alto-main .blueBoxContainer{
        flex: 0 0 350px !important;
        height: auto !important;
    }
    .join-alto-main .membershipCriteria{
        width: 100% !important;
        padding: 40px 15px !important;
    }
}
@media (max-width: 1300px){
    .why-should-join-alto-items-dart-box .why-should-join-alto-items-dart-box-vectorBox{
        width: 350px !important;
        gap: 10px !important;
    }
}
@media (max-width: 1150px){
    .why-should-join-alto-items-dart-box .why-should-join-alto-items-dart-box-vectorBox{
        width: 100% !important;
        text-align: center !important;
    }
    .why-should-join-alto-items-dart-box .why-should-join-alto-items-dart-box-vectorBox .boxParagraph{
        display: block !important;
        align-items: center !important;
        text-align: center;
        width: 500px;
        margin-right: auto;
        margin-left: auto;
    }
}
@media (max-width: 1110px){
    .join-alto-main .blueBoxContainers{
        justify-content: unset !important;

    }
}
@media (max-width: 1000px){
    .join-alto-main .commitBox .commitBoxSubDiv{
        padding: 20px 0px !important;
        width: 100% !important;
    }
    .join-alto-main .commitBox .commitBoxSubDiv .boxParagraph{
        width: 100% !important;
        padding: 0px !important;
    }
    .membershipCriteria .items .item .imageAndDescription{
        flex-direction: column !important;
        width: 100% !important;
        gap: 20px !important;
    }
    .membershipCriteria .items .item .imageAndDescription .membershipCriteriaImage{
        width: 100% !important;
        height: auto !important;
    }
    .membershipCriteria .items .item .imageAndDescription .membershipCriteriaImage img{
        max-width: 100% !important;
        height: auto !important;
        margin-right: auto;
        margin-left: auto;
    }
    .membershipCriteria .items .item .imageAndDescription .membershipCriteriaImage .title{
        margin-right: auto;
        margin-left: auto;
    }
    .membershipCriteria .items .item .imageAndDescription .membershipCriteriaDescription{
        width: 100% !important;
        align-self: normal !important;
        margin: 0px !important;
        text-align: start !important;
    } 
}
@media (max-width: 750px){

    .join-alto-main .offerBox .headings-column .special-offer-description{
        width: 50%;
    }
    .join-alto-main .offerBox .headings-column .special-offer-description .description, .title{
        font-size: 13px !important;
    }
    .join-alto-main .offerBox .headings-column .special-offer-expires{
        width: 25%;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 13px !important;
    }
    .join-alto-main .offerBox .headings-column .special-offer-savings{
        width: 25%;
        font-size: 13px !important;
    }
    .join-alto-main .offerBox .headings-row h1:nth-child(1){
        width: 50%;
        font-size: 13px !important;
    }
    .join-alto-main .offerBox .headings-row h1:nth-child(2){
        width: 25%;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 13px !important;
    }
    .join-alto-main .offerBox .headings-row h1:nth-child(3){
        width: 25%;
        font-size: 13px !important;
    }
    .join-alto-main .offerBox .offerBoxInner{
        padding: 20px !important;
    }
}
@media (max-width: 800px){
    .howtoJoinAltoJoinAltoDiv .borderBox{
        width: 100% !important;
        padding: 15px !important;
    }
}
@media (max-width: 750px){  
    .membershipCriteria .items .item .membershipCriteriaDescription p{
        text-align: center !important;
    }
}
@media (max-width: 650px){
    .why-should-join-alto-items-dart-box .why-should-join-alto-items-dart-box-vectorBox .boxParagraph{
        width: 100%;
    }
}
@media (max-width: 450px){
    .membershipCriteria .items .item .imageAndDescription .bottom{
        flex-direction: column !important;
        gap: 20px !important;
    }   
    .membershipCriteria .items .item .imageAndDescription .bottom button{
        width: 100% !important;
        margin-top: 10px;
    }
    .membershipCriteria .items .item .imageAndDescription .membershipCriteriaDescription .btnJoinNow{
        margin: 0px !important;
        width: 100%;
    }
}
