.peopleCardComponent .cardContent{
    padding: 20px;
    text-align: start;
}
.peopleCardComponent .cardContent .title{
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.peopleCardComponent .cardContent .dateText{
    color: var(--Black, #222);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.peopleCardComponent .cardContent .description p{
    overflow: hidden;
    color: var(--Black, #222);
    text-overflow: ellipsis;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.peopleCardComponent .cardContent .readMore p{
    color: var(--Blue, #00529C);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
}
@media (max-width: 1300px){
    /* .peopleCardComponent{
        width: 100% !important;
        height: auto !important;
    } */
}